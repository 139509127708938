import React from 'react'
import styled from 'styled-components';
import { Button } from '../components/Button';
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import logo from "../images/logo.svg"
import { GlobalStyle } from '../components/styles/GlobalStyles';
import SEO from '../components/seo';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card } from "react-bootstrap";
import Layout from '../components/layout'
import TabsAfya from './../components/TabsAfya';

const Patameds_LandingPage = () => {
    const data = useStaticQuery(graphql`
        query AfyabookLandingPageQuery {
            allLandingPagesJson {
            edges {
                node {
                alt9
                img9 {
                    childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                    }
                }
                alt10
                img10 {
                    childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                    }
                }
                }
            }
            }
        }
      
    `)

    const getImage = (data) => {
        const imageArray = []
        data.allLandingPagesJson.edges.forEach((element, index) => {
            imageArray.push(
                <div key={index}>
                    <Image
                        alt={element.node.alt9}
                        fluid={element.node.img9.childImageSharp.fluid}>
                    </Image>

                </div>
            )
        })
        return imageArray
    }

    const getImageSignup = (data) => {
        const imageArray = []
        data.allLandingPagesJson.edges.forEach((element, index) => {
            imageArray.push(
                <div key={index}>
                    <Image
                        alt={element.node.alt10}
                        fluid={element.node.img10.childImageSharp.fluid}>
                    </Image>

                </div>
            )
        })
        return imageArray
    }


    return (
        <Layout>
            <Container>
                <SEO title="Afyabook: Find medication and healthcare services in Kenya"
                    keywords="medication suppliers in Kenya,online pharmacy in kenya,chemist in Kenya, pharmacy,Pharmaceutical retailers in Kenya, telemedicine,medication therapy management, remote patient monitoring, selfcare"
                    description="Easily buy medication online from verified pharmacies in Kenya. Discover products at better rates, transact and get them delivered. Learn more"
                    image="https://www.tripleaimsoftware.com/static/77c3c20deedfdeeb0c2897adb37e5a97/14b42/drugcommerce.jpg"
                    siteUrl="https://www.tripleaimsoftware.com/afyabook/"
                    canonical="https://www.tripleaimsoftware.com/afyabook/"
                >
                </SEO>
                <GlobalStyle />
                <img src={logo} alt="logo" css={`width: 130px; height: 130px;position: absolute; top: -30px; left: 10px`} />
                <Hero>
                    <CtaWrapper>
                        <ContainerP>Increase Revenue while Saving on Time and Cost</ContainerP>
                        <ContainerP2>Pharmaceutical marketplace enabling data-driven decision making in sales and provision of medicine and healthcare services</ContainerP2>
                        <Button as="button" primary="true" css={`width: 100%; height: 50px;background:#03989E;border:none`}><a css={`color: #fff`} href='/request/'>Get Started</a></Button>
                    </CtaWrapper>
                    <ImgWrapper>
                        {getImage(data)}
                    </ImgWrapper>
                </Hero>
                <ContainerP css={`text-align: center;`} >Afyabook Value proposition</ContainerP>
                <Intro>
                    <Cards>
                        <div className="card-body">
                            <h5 className="card-title">Enabling online orders from patients</h5>
                            <p css={`border-top: 1px solid #fff;`} className="card-text">Enabling patients to order medication and healthcare services from registered pharmaceutical retailers, clinics and hospitals.</p>
                            {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                        </div>
                    </Cards>
                    <Cards>
                        <div className="card-body">
                            <h5 className="card-title">Enabling data-driven decision making</h5>
                            <p css={`border-top: 1px solid #fff;`} className="card-text">Provision of data analytics services to providers and patients based on the data generated from the e-commerce platform and health information management system.</p>
                            {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                        </div>
                    </Cards>
                    <Cards>
                        <div className="card-body">
                            <h5 className="card-title">Enabling omnichannel marketing of healthcare products and services</h5>
                            <p css={`border-top: 1px solid #fff;`} className="card-text">Enabling healthcare providers to market their products and services to patients within the platform.</p>
                            {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                        </div>
                    </Cards>
                    <Cards>
                        <div className="card-body">
                            <h5 className="card-title">User friendly Health Information Management System</h5>
                            <p css={`border-top: 1px solid #fff;`} className="card-text">Enabling automation of pharmacy operations, medication therapy management, remote patient monitoring, clinicals and patient engagement.</p>
                            {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                        </div>
                    </Cards>
                </Intro>
                <Client>
                    <TabsAfya />
                </Client>
                <Hero>
                    <CtaWrapper>
                        <ContainerP2 css={`font-size: 1.5rem;font-weight: bold;`}>Join other healthcare providers caring for patients through technology with Afyabook</ContainerP2>
                        <Button as="button" primary="true" css={`width: 100%; height: 50px;background:#03989E;border:none`}><a css={`color: #fff`} href='/request/'>Sign up</a></Button>
                    </CtaWrapper>
                    <ImgWrapper>
                        {getImageSignup(data)}
                    </ImgWrapper>
                </Hero>




            </Container>
        </Layout>
    )
}

export default Patameds_LandingPage

const Container = styled.div`
    height: 100%;
    width: 100%;
    margin:0px;
    padding:0px;
`
const Hero = styled.div`
    display:flex;
    height: 80vh;
    margin-top: 5rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap-reverse;
`
const CtaWrapper = styled.div`
    margin-left: 1rem;
    padding:0rem 3rem;
    flex: 1 1 30em;
    
`
const ImgWrapper = styled.div`
    flex: 1 1 30em;
`
const Image = styled(Img)`
   max-width: 600px;
   margin-left: auto;
   margin-right: auto;
`
const ContainerP = styled.h2`
    font-size: clamp(1.5rem,2vw, 2rem);
    //text-align: center;
    color: #000;
    margin-bottom: 1rem;
`
const ContainerP2 = styled.p`
    font-size: clamp(1rem,2vw, 1.2rem);
    //text-align: center;
    color: #000;
    margin-bottom: 1rem;
`
const Intro = styled.div`
    width: 100%;
    height: 100%;
    display: flex;  
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 1rem;
`
const Cards = styled(Card)`
    width: 17.5%;
    height: 400px;
    background: #03989E;
    color: #fff;  
    min-width:200px;
`
const Client = styled.div`
    width: 100%;
    height: 100%;
    //background: blue;    
`

