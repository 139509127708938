import React from 'react'
import styled from 'styled-components';
import { useState } from "react";
import "./styles/TabsAfya.css"
import { Card } from "react-bootstrap";

const TabsAfya = () => {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
        <div className="container">
            <div className="bloc-tabs">
                <button
                    className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(1)}
                >
                    Patients
                </button>
                <button
                    className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(2)}
                >
                    Hospitals/Retail Pharmacy
                </button>
            </div>

            <div className="content-tabs">


                <div
                    className={toggleState === 1 ? "content  active-content" : "content"}
                >
                    <h2>Patients</h2>
                    <CardsA>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Convenience</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Order medication and healthcare services online from a wide selection of providers.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Adopt Preventive Therapy</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Get healthcare services like Medication Therapy Management, telehealth, self-care guidance.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Manage Your Health Information</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Manage your healthcare information and leverage your data for better health.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Read>
                            <a href='/request/' class="btn btn-primary"  >Sign up to join the pilot program</a>
                        </Read>
                    </CardsA>
                </div>

                <div
                    className={toggleState === 2 ? "content  active-content" : "content"}
                >
                    <h2>Hospitals/Retail Pharmacy</h2>
                    <CardsA>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">EMR Automated Data Analysis</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Electronic medical record automatic data analysis (patient data, financial,operations) for better decision making in patient treatment.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Clients</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Get online clients/orders from patients within the platform – increase revenue.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Data Driven Decisions</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Customer purchase data analysis for better decision making in marketing, customer satisfaction and inventory management.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Data Driven Decisions</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Transactional data analysis (costs and quantities) for better decision making in medication purchase.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Generate Demand</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Advertise on the platform to patients – decreasing time to market.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Expand Your Reach</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Market through health education to attract more clients.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Save Cost and Time</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>We send emails to our email list to market your special offers.</p>
                            </div>
                        </Cards>
                    </CardsA>
                    <Read>
                        <a href='/request/' class="btn btn-primary"  >Sign up to join the pilot program</a>
                    </Read>
                </div>

            </div>
        </div>
    )
}

export default TabsAfya


const Cards = styled(Card)`
    min-width: 200px;
    width: 40%;    
    background: #03989E;
    color: #fff;  
    margin-top:1rem;
    margin-left:1rem;
`
const CardsA = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
    justify-content: center; 
    
    @media screen and (min-width: 500px) {
        justify-content: space-between; 
    }
`
const Read = styled.div`
    margin-top: 1rem;
    margin-left:1rem;
   
`
